import image26 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (1).webp";
import image27 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (10).webp";
import image28 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (11).webp";
import image29 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (12).webp";
import image30 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (13).webp";
import image31 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (14).webp";
import image32 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (15).webp";
import image33 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (2).webp";
import image34 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (3).webp";
import image35 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (4).webp";
import image36 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (5).webp";
import image37 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (6).webp";
import image38 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (7).webp";
import image39 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (8).webp";
import image40 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM (9).webp";
import image41 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.09 PM.webp";

const carThree = [
  {
    img: image26,
    title: "Renken",
  },
  {
    img: image27,
    title: "Renken",
  },
  {
    img: image28,
    title: "Renken",
  },
  {
    img: image29,
    title: "Renken",
  },
  {
    img: image30,
    title: "Renken",
  },
  {
    img: image31,
    title: "Renken",
  },
  {
    img: image32,
    title: "Renken",
  },
  {
    img: image33,
    title: "Renken",
  },
  {
    img: image34,
    title: "Renken",
  },

  {
    img: image35,
    title: "Renken",
  },

  {
    img: image36,
    title: "Renken",
  },

  {
    img: image37,
    title: "Renken",
  },

  {
    img: image38,
    title: "Renken",
  },

  {
    img: image39,
    title: "Renken",
  },

  {
    img: image40,
    title: "Renken",
  },

  {
    img: image41,
    title: "Renken",
  },
];

export { carThree };
