import image16 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (1).webp";
import image17 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (2).webp";
import image18 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (3).webp";
import image19 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (4).webp";
import image20 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (5).webp";
import image21 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (6).webp";
import image22 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (7).webp";
import image23 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (8).webp";
import image24 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM (9).webp";
import image25 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.17.26 PM.webp";

const carTwo = [
  {
    img: image23,
    title: "BMW",
  },
  {
    img: image24,
    title: "BMW",
  },
  {
    img: image16,
    title: "BMW",
  },
  {
    img: image17,
    title: "BMW",
  },
  {
    img: image18,
    title: "BMW",
  },
  {
    img: image19,
    title: "BMW",
  },
  {
    img: image20,
    title: "BMW",
  },
  {
    img: image21,
    title: "BMW",
  },
  {
    img: image22,
    title: "BMW",
  },

  {
    img: image25,
    title: "BMW",
  },
];

export { carTwo };
