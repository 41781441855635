import image87 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.31 PM (1).webp";
import image88 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.31 PM (2).webp";
import image89 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.31 PM (3).webp";
import image90 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.31 PM (4).webp";
import image91 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.31 PM.webp";

const carSeven = [
  {
    img: image87,
    title: "Tesla",
  },
  {
    img: image88,
    title: "Tesla",
  },
  {
    img: image89,
    title: "Tesla",
  },
  {
    img: image90,
    title: "Tesla",
  },
  {
    img: image91,
    title: "Tesla",
  },
];

export { carSeven };
