import image59 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (1).webp";
import image60 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (10).webp";
import image61 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (11).webp";
import image62 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (12).webp";
import image63 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (13).webp";
import image64 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (14).webp";
import image65 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (15).webp";
import image66 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (16).webp";
import image67 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (17).webp";
import image68 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (18).webp";
import image69 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (19).webp";
import image70 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (2).webp";
import image71 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (20).webp";
import image72 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (21).webp";
import image73 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (22).webp";
import image74 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (23).webp";
import image75 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (24).webp";
import image76 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (25).webp";
import image77 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (26).webp";
import image78 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (27).webp";
import image79 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (3).webp";
import image80 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (4).webp";
import image81 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (5).webp";
import image82 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (6).webp";
import image83 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (7).webp";
import image84 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (8).webp";
import image85 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM (9).webp";
import image86 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.20.58 PM.webp";

const carSix = [
  {
    img: image77,
    title: "Porsche Cayenne",
  },
  {
    img: image59,
    title: "Porsche Cayenne",
  },
  {
    img: image60,
    title: "Porsche Cayenne",
  },
  {
    img: image61,
    title: "Porsche Cayenne",
  },
  {
    img: image62,
    title: "Porsche Cayenne",
  },
  {
    img: image63,
    title: "Porsche Cayenne",
  },
  {
    img: image64,
    title: "Porsche Cayenne",
  },
  {
    img: image65,
    title: "Porsche Cayenne",
  },
  {
    img: image66,
    title: "Porsche Cayenne",
  },
  {
    img: image67,
    title: "Porsche Cayenne",
  },
  {
    img: image68,
    title: "Porsche Cayenne",
  },
  {
    img: image70,
    title: "Porsche Cayenne",
  },
  {
    img: image71,
    title: "Porsche Cayenne",
  },
  {
    img: image69,
    title: "Porsche Cayenne",
  },
  {
    img: image72,
    title: "Porsche Cayenne",
  },
  {
    img: image73,
    title: "Porsche Cayenne",
  },
  {
    img: image74,
    title: "Porsche Cayenne",
  },
  {
    img: image75,
    title: "Porsche Cayenne",
  },
  {
    img: image76,
    title: "Porsche Cayenne",
  },
  {
    img: image78,
    title: "Porsche Cayenne",
  },
  {
    img: image79,
    title: "Porsche Cayenne",
  },
  {
    img: image80,
    title: "Porsche Cayenne",
  },
  {
    img: image81,
    title: "Porsche Cayenne",
  },
  {
    img: image82,
    title: "Porsche Cayenne",
  },
  {
    img: image83,
    title: "Porsche Cayenne",
  },
  {
    img: image84,
    title: "Porsche Cayenne",
  },
  {
    img: image85,
    title: "Porsche Cayenne",
  },
  {
    img: image86,
    title: "Porsche Cayenne",
  },
];

export { carSix };
