import image42 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (1).webp";
import image43 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (2).webp";
import image44 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (3).webp";
import image45 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (4).webp";
import image46 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (5).webp";
import image47 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (6).webp";
import image48 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (7).webp";
import image49 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM (8).webp";
import image50 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.18.52 PM.webp";

const carFour = [
  {
    img: image50,
    title: "BMW",
  },
  {
    img: image43,
    title: "BMW",
  },
  {
    img: image42,
    title: "BMW",
  },
  {
    img: image44,
    title: "BMW",
  },
  {
    img: image45,
    title: "BMW",
  },
  {
    img: image46,
    title: "BMW",
  },
  {
    img: image47,
    title: "BMW",
  },
  {
    img: image48,
    title: "BMW",
  },
  {
    img: image49,
    title: "BMW",
  },
];

export { carFour };
