import image51 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (1).webp";
import image52 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (2).webp";
import image53 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (3).webp";
import image54 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (4).webp";
import image55 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (5).webp";
import image56 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (6).webp";
import image57 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM (7).webp";
import image58 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.19.17 PM.webp";

const carFive = [
  {
    img: image51,
    title: "Jaguar",
  },
  {
    img: image52,
    title: "Jaguar",
  },
  {
    img: image53,
    title: "Jaguar",
  },
  {
    img: image54,
    title: "Jaguar",
  },
  {
    img: image55,
    title: "Jaguar",
  },
  {
    img: image56,
    title: "Jaguar",
  },
  {
    img: image57,
    title: "Jaguar",
  },
  {
    img: image58,
    title: "Jaguar",
  },
];

export { carFive };
