import image2 from "./../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (1).webp";
import image3 from "./../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (10).webp";
import image5 from "./../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (2).webp";
import image6 from "./../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (3).webp";
import image8 from "./../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM.webp";
import image10 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (4).webp";
import image11 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (5).webp";
import image12 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (6).webp";
import image13 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (7).webp";
import image14 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (8).webp";
import image15 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.16.51 PM (9).webp";

const carOne = [
  {
    img: image3,
    title: "BMW",
  },
  {
    img: image2,
    title: "BMW",
  },
  {
    img: image5,
    title: "BMW",
  },
  {
    img: image6,
    title: "BMW",
  },
  {
    img: image8,
    title: "BMW",
  },
  {
    img: image10,
    title: "BMW",
  },
  {
    img: image11,
    title: "BMW",
  },
  {
    img: image12,
    title: "BMW",
  },
  {
    img: image13,
    title: "BMW",
  },
  {
    img: image14,
    title: "BMW",
  },
  {
    img: image15,
    title: "BMW",
  },
];

export { carOne };
