import image92 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (1).webp";
import image93 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (2).webp";
import image94 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (3).webp";
import image95 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (4).webp";
import image96 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (5).webp";
import image97 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (6).webp";
import image98 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (7).webp";
import image99 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM (8).webp";
import image100 from "../assets/gallery/WhatsApp Image 2023-08-01 at 1.21.58 PM.webp";

const carEight = [
  {
    img: image100,
    title: "Jaguar",
  },
  {
    img: image92,
    title: "Jaguar",
  },
  {
    img: image93,
    title: "Jaguar",
  },
  {
    img: image94,
    title: "Jaguar",
  },
  {
    img: image95,
    title: "Jaguar",
  },
  {
    img: image99,
    title: "Jaguar",
  },
  {
    img: image96,
    title: "Jaguar",
  },
  {
    img: image97,
    title: "Jaguar",
  },
  {
    img: image98,
    title: "Jaguar",
  },
];

export { carEight };
